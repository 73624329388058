const CLASS_SELECTOR = ".TabContainer";
const TAB_SELECTOR = ".Tab";
const DROPDOWN_SELECTOR = ".TabContainer-dropdown";
const TAB_CONTENT_SELECTOR = ".TabContent";

const ACTIVE_CLASS = "is-active";

const instances = [];


function init(PageComponents, container = null) {
    if (!container || !container.querySelectorAll) {
        container = document;
    }
    const tabContainers = container.querySelectorAll(CLASS_SELECTOR);
    window.forEachNode(tabContainers, (tabContainerElement) => {
        if (tabContainerElement.classList.contains('is-standalone')) return;

        if (!tabContainerElement.hasAttribute('data-initialized')) {
            tabContainerElement.setAttribute('data-initialized', 'true');

            const tabTarget = tabContainerElement.getAttribute("data-target");
            const tabContentContainer = document.getElementById(tabTarget) || document.querySelector(tabTarget);
            if (!tabContentContainer) {
                console.error(`Error: could not find content target "${tabTarget}" for tab container`, tabContainerElement);
                return;
            }

            const tabElements = tabContainerElement.querySelectorAll(TAB_SELECTOR);
            const contentElements = tabContentContainer.querySelectorAll(TAB_CONTENT_SELECTOR);
            if (tabElements.length !== contentElements.length) {
                console.error(`Error: tab count mismatch, ${tabElements.length} vs ${contentElements.length}`, tabContainerElement);
            }

            const dropdownElement = tabContainerElement.querySelector(DROPDOWN_SELECTOR);

            const tabs = [];
            //const options = [];
            let activeTabIndex = -1;

            function syncTabs(index) {
                window.forEachNode(tabElements, (tab, i) => {
                   tab.classList.toggle(ACTIVE_CLASS, i === index);
                });
            }
            function syncDropdown(index) {
                dropdownElement.selectedIndex = index;
            }
            function syncContent(index) {
                window.forEachNode(contentElements, (content, i) => {
                    content.classList.toggle(ACTIVE_CLASS, i === index);
                });
            }

            function sync(index) {
                syncTabs(index);
                syncContent(index);
                activeTabIndex = index;
            }

            window.forEachNode(tabElements, (tab, index) => {
                if (tab.classList.contains(ACTIVE_CLASS)) {
                    activeTabIndex = index;
                }

                // Create dropdown option
                const option = document.createElement("option");
                option.value = index;
                option.text = tab.innerText;
                dropdownElement.appendChild(option);
                //options.push(option);

                // Add desktop tab click handler
                tab.addEventListener('click', (e) => {
                    e.stopPropagation();
                    sync(index);
                    syncDropdown(index);
                });
            });
            if (activeTabIndex > -1) sync(activeTabIndex);

            // Dropdown change handler
            dropdownElement.addEventListener('change', (e) => {
                e.stopPropagation();
                sync(dropdownElement.selectedIndex);
            });

            instances.push({
                tabs,
                sync
            });
        }
    });

}

module.exports = {
    instances,
    init
};
